<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col lg="2" md="2" sm="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <b-col lg="3" md="3" sm="12" class="d-flex align-items-left justify-content-end mb-1 mb-md-0 ">
              <!-- <v-select :options="leadOptions" v-model="treeStatus" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Lead Status" /> -->
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="treeStatus" valueFormat="object" :limit="2" class="per-page-selector d-inline-block w-100" placeholder="Filter Report" />
            </b-col>

            <b-col lg="2" md="2" sm="10" class="d-flex align-items-left justify-content-start  mb-1 mb-md-0">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
            </b-col>

            <b-col lg="1" md="1" sm="2" class="d-flex align-items-left justify-content-start  mb-1 mb-md-0">
              <b-button @click="clearDate()" variant="success">Clear</b-button>
            </b-col>

            <!-- Search -->

            <b-col lg="4" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" @click="generateReport" v-if="$Can('inventory_create')">
                  <span class="text-nowrap">Generate Report</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row cols="12" class="mt-2">
            <!-- solid color -->
            <b-col md="6" xl="3">
              <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                <b-card-title class="text-success">
                  {{ formatPrice(totalSellingPrice, 2) }}
                </b-card-title>
                <b-card-text>
                  Total Selling Price
                </b-card-text>
              </b-card>
            </b-col>

            <!-- solid color -->
            <b-col md="6" xl="3">
              <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                <b-card-title class="text-danger">
                  {{ formatPrice(totalCostOfSales, 2) }}
                </b-card-title>
                <b-card-text>
                  Total Purchase Price
                </b-card-text>
              </b-card>
            </b-col>

            <!-- solid color -->
            <b-col md="6" xl="3">
              <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                <b-card-title :class="profitClass"> {{ formatPrice(totalProfit, 2) }} </b-card-title>
                <b-card-text> Total Profit </b-card-text>
              </b-card>
            </b-col>

            <!-- solid color -->
            <b-col md="6" xl="3">
              <b-card class="text-center" border-variant="secondary" bg-variant="transparent">
                <b-card-title v-if="filterDateStart && filterDateEnd"> {{ filterDateStart }} - {{ filterDateEnd }} </b-card-title>
                <b-card-title v-else>
                  All Records
                </b-card-title>
                <b-card-text>
                  Data Range
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="listInventorys"
          :filter="filter"
          filter-debounce="250"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="inHeaders"
          show-empty
          empty-text="No matching records found"
          @row-clicked="(item) => $set(item, '_showDetails', !item._showDetails)"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <!-- Column: Id -->
          <template #cell(stockNumber)="data">
            <!-- <b-link :to="{ name: 'inventory-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.item.stockNumber ? data.item.stockNumber : 'N/A' }}</strong>
          </template>

          <template #cell(closingDate)="data">
            <span class="font-weight-bold"> {{ data.item.closingDate ? dateFormat(data.item.closingDate) : 'N/A' }} </span>
          </template>

          <template #cell(titleValue)="data">
            <span class="font-weight-bold"> {{ data.item.titleValue ? data.item.titleValue : 'N/A' }} </span>
          </template>

          <!-- Column: Listing Price -->
          <template #cell(listingPrice)="data">
            <span class="font-weight-bold"> {{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }} </span>
          </template>

          <template class="font-weight-bold" #cell(sellingPrice)="data">
            <span class="font-weight-bold"> {{ data.item.sellingPrice ? formatPrice(data.item.sellingPrice, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(grossProfit)="data">
            <span class="font-weight-bold"> {{ data.item.grossProfit ? formatPrice(data.item.grossProfit, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(pack)="data">
            <span class="font-weight-bold"> {{ data.item.pack ? formatPrice(data.item.pack, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(cogsTotal)="data">
            <span class="font-weight-bold"> {{ data.item.cogsTotal ? formatPrice(data.item.cogsTotal, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(purchasePrice)="data">
            <span class="font-weight-bold"> {{ data.item.purchasePrice ? formatPrice(data.item.purchasePrice, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(cogsTotalIncome)="data">
            <span class="font-weight-bold"> {{ data.item.cogsTotalIncome ? formatPrice(data.item.cogsTotalIncome, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(adjustmentUpTotal)="data">
            <span class="font-weight-bold"> {{ data.item.adjustmentUpTotal ? formatPrice(data.item.adjustmentUpTotal, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(adjustmentDownTotal)="data">
            <span class="font-weight-bold"> {{ data.item.adjustmentDownTotal ? formatPrice(data.item.adjustmentDownTotal, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(purchaseTotal)="data">
            <span class="font-weight-bold"> {{ data.item.purchaseTotal ? formatPrice(data.item.purchaseTotal, 2) : formatPrice(0) }} </span>
          </template>

          <template #cell(consigment)="data">
            <b-badge v-if="data.item.consigment == true" pill variant="success" class="badge-glow">Yes</b-badge>
            <b-badge v-if="data.item.consigment == false" pill variant="danger" class="badge-glow">No</b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="info" class="badge-glow">Created</b-badge>
            <b-badge v-if="data.item.status == 'B'" pill variant="warning" class="badge-glow">Incoming - Consigment</b-badge>
            <b-badge v-if="data.item.status == 'C'" pill variant="success" class="badge-glow">In Stock - Consigment</b-badge>
            <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow">Incoming </b-badge>
            <b-badge v-if="data.item.status == 'I'" pill variant="light-info" class="badge-glow">In Stock Pending</b-badge>
            <b-badge v-if="data.item.status == 'E'" pill variant="light-success" class="badge-glow">In Stock - Pending Receiving</b-badge>
            <b-badge v-if="data.item.status == 'F'" pill variant="secondary" class="badge-glow">In Stock - Pending Payment</b-badge>
            <b-badge v-if="data.item.status == 'P'" pill variant="danger" class="badge-glow">Created</b-badge>
            <b-badge v-if="data.item.status == 'G'" pill variant="primary" class="badge-glow" v-b-tooltip.hover.top="'Associated with purchase quote or sale trade in quote, vehicle received and payment not received'">In Stock</b-badge>

            <b-badge v-if="data.item.status == 'PA'" pill variant="light-success" class="badge-glow">Sold Pending</b-badge>
            <b-badge v-if="data.item.status == 'PB'" pill variant="warning" class="badge-glow">Delivered - Pending Payment</b-badge>
            <b-badge v-if="data.item.status == 'PC'" pill variant="success" class="badge-glow">Sold</b-badge>
            <b-badge v-if="data.item.status == 'PD'" pill variant="light-info" class="badge-glow">Delivered</b-badge>
            <b-badge v-if="data.item.status == 'PE'" pill variant="secondary" class="badge-glow">Closed</b-badge>

            <b-badge v-if="data.item.status == 'PF'" pill variant="secondary" class="badge-glow">In Stock Pending - Sold Order</b-badge>
            <b-badge v-if="data.item.status == 'PL'" pill variant="secondary" class="badge-glow">In Stock Pending - Sold Order</b-badge>

            <b-badge v-if="data.item.status == 'PG'" pill variant="secondary" class="badge-glow">Incoming - Sold Order</b-badge>
            <b-badge v-if="data.item.status == 'PH'" pill variant="secondary" class="badge-glow">Consigment - Sold Order</b-badge>
            <b-badge v-if="data.item.status == 'PI'" pill variant="secondary" class="badge-glow">Sold Order</b-badge>
            <b-badge v-if="data.item.status == 'PK'" pill variant="secondary" class="badge-glow">Funded - Pending Delivery</b-badge>
          </template>

          <template #cell(condition)="data">
            <b-badge v-if="data.item.condition == 'NEW'" active variant="light-info" class="badge-glow ">New</b-badge>
            <b-badge v-if="data.item.condition == 'USED'" variant="light-success" class="badge-glow ">Used </b-badge>
          </template>

          <template #cell(consignment)="data">
            <b-badge v-if="data.item.consignment == true" active variant="light-success" class="badge-glow ">Consignment</b-badge>
            <b-badge v-if="data.item.consignment == false" variant="light-danger" class="badge-glow ">Non Consignment </b-badge>
          </template>

          <template #cell(received)="data">
            <b-badge v-if="data.item.received == true" active variant="light-success" class="badge-glow ">Received</b-badge>
            <b-badge v-if="data.item.received == false" variant="light-danger" class="badge-glow ">Not Received </b-badge>
          </template>

          <template #cell(user)="data"> {{ data.item.user ? data.item.user : 'N/A' }} </template>
          <template #cell(vinNo)="data">{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</template>

          <template #cell(msrpCurrencyValue)="data">{{ data.item.msrpCurrencyValue ? data.item.msrpCurrencyValue : 'N/A' }}</template>
          <template #cell(userId)="data"> {{ data.item.userId ? data.item.userId : 'N/A' }} </template>
          <template #cell(appraisedPrice)="data"> {{ data.item.appraisedPrice ? formatPrice(data.item.appraisedPrice, 2) : formatPrice(0) }} </template>
          <template #cell(additionalTotal)="data"> {{ data.item.additionalTotal ? formatPrice(data.item.additionalTotal, 2) : formatPrice(0) }} </template>

          <template #cell(accessories)="data"> {{ data.item.accessories ? data.item.accessories : 'N/A' }} </template>
          <template #cell(totalMsrpOptions)="data">{{ data.item.totalMsrpOptions ? formatPrice(data.item.totalMsrpOptions, 2) : formatPrice(0) }} </template>
          <template #cell(msrpTotal)="data"> {{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }} </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col>
                  <b-table
                    striped
                    hover
                    :bordered="true"
                    class="position-relative text-center"
                    selectable
                    select-mode="single"
                    responsive
                    :small="true"
                    head-variant="light"
                    show-empty
                    empty-text="No matching records found"
                    @row-selected="onRowPurchaseSelected"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :fields="quoteFields"
                    :items="row.item.expenses.concat(row.item.incomes)"
                  >
                    <template #cell(orderId)="row">
                      <strong class="text-primary">#{{ Number(row.item.orderId) }}</strong>
                    </template>

                    <template #cell(title)="row">
                      <span>{{ row.item.title ? row.item.title + ' ' + row.item.title : 'N/A' }}</span>
                    </template>

                    <template #cell(price)="row">
                      <strong v-if="row.item.type == 'Purchase'" class="text-danger"> {{ row.item.price ? formatPrice(row.item.price, 2) : formatPrice(0) }} </strong>
                      <strong v-if="row.item.type == 'Sale'" class="text-success"> {{ row.item.price ? formatPrice(row.item.price, 2) : formatPrice(0) }} </strong>
                      <strong v-if="row.item.type == 'Sale Additional'" class="text-success"> {{ row.item.price ? formatPrice(row.item.price, 2) : formatPrice(0) }} </strong>
                      <strong v-if="row.item.type == 'Lease Additional'" class="text-success"> {{ row.item.price ? formatPrice(row.item.price, 2) : formatPrice(0) }} </strong>

                    </template>

                    <template #cell(type)="row">
                      {{ row.item.type ? row.item.type : 'N/A' }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
            </b-card>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: { Treeselect, vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, flatPickr },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const periodOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'This Month', value: 'A' },
      { text: 'This Quarter', value: 'B' },
      { text: 'This Year', value: 'C' },
      { text: 'Last Month', value: 'D' }, // { text: "Last Month", value: "A" },
      { text: 'Last Quarter', value: 'E' }, // { text: "Last Year", value: "B" },
      { text: 'Last Year', value: 'F' }, // { text: "Last Year", value: "B" },
      { text: 'All Time', value: 'G' },
    ];

    const statusWebsiteOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'Published', value: 'yes' },
      { text: 'Unpublished', value: 'no' },
    ];

    const tempStatusOptions = [
      // { text: 'Inventory Status Selection', value: '' },
      { text: 'Created', value: 'A' },
      { text: 'Incoming - Consigment', value: 'B' },
      { text: 'In Stock - Consigment', value: 'C' },
      { text: 'Incoming', value: 'D' },
      { text: 'In Stock Pending', value: 'I' },
      { text: 'In Stock - Pending Receiving', value: 'E' },
      { text: 'In Stock - Pending Payment', value: 'F' },
      { text: 'In Stock', value: 'G' },
      { text: 'Created', value: 'P' },

      { text: 'Sold Pending', value: 'PA' },
      { text: 'Delivered - Pending Payment', value: 'PB' },
      { text: 'Sold', value: 'PC' },
      { text: 'Delivered', value: 'PD' },
      { text: 'Closed', value: 'PE' },

      { text: 'In Stock Pending - Sold Order', value: 'PF' },
      { text: 'In Stock Pending - Sold Order', value: 'PL' },

      { text: 'Incoming - Sold Order', value: 'PG' },
      { text: 'Consigment - Sold Order', value: 'PH' },
      { text: 'Sold Order', value: 'PI' },
      { text: 'Funded - Pending Delivery', value: 'PK' },
    ];

    const tempQuoteStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Sale', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    const userOptions = [];

    return {
      userheaders: ['stockNumber', 'purchasePrice', 'cogsTotal', 'purchaseTotal', 'cogsTotalIncome', 'sellingPrice', 'grossProfit'],
      filter: null,
      filterOn: ['id', 'titleValue', 'status', 'listingPrice', 'publish','stockNumber','vinNo'],

      quoteFields: [
        { key: 'orderId', label: 'ORDER ID', class: 'text-center', thStyle: 'width: 5%' },
        { key: 'type', label: 'ORDER TYPE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'title', label: 'ITEM TITLE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'description', label: 'DESCRIPTION', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'date', label: 'DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 15%' },
      ],

      config: {
        dateFormat: 'd/m/Y',
        mode: 'range',
      },
      userOptions,
      treeStatus: null,

      perPage: localStorage.getItem('inventoryShow') ?? 8,
      fetchInventorys: [],
      listInventorys: [],
      currentPage: 1,
      perPageOptions: [5, 8, 25, 50, 100],
      tempQuoteStatusOptions,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusWebsiteOptions,
      tempStatusOptions,
      statusOptions: [],
      statusWebsite: null,
      status: null,
      colWidthFactor: null,
      periodOptions,
      selectedPeriod: null,
      totalPurchasePrice: null,
      totalSellingPrice: null,
      totalCostOfSales: null,
      totalProfit: null,
      filterDateStart: null,
      filterDateEnd: null,
      inHeaders: [],
      rangeDate: null,
      profitClass: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    treeStatus: {
      handler: function(val, before) {
        if (before != null) {
          if (val.length != before.length) {
            this.getItems();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.getItems();
          }
        }
      },
    },

    perPage: {
      handler: function(val, before) {
        localStorage.setItem('inventoryShow', val);
      },
    },
  },

  methods: {
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.getItems();
      }
    },
    calculateClass() {
      if (this.totalProfit > 0) {
        this.profitClass = 'text-success';
      } else {
        this.profitClass = 'text-danger';
      }
    },

    sellTotal(item) {
      return item.sellingPrice + item.cogsTotalIncome;
    },
    generateReport() {
      this.download = true;
      store.dispatch('accounting/pdfDownload', { start: this.filterDateStart, end: this.filterDateEnd, type: 'sale', filterUser: this.treeStatus }).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Sale Report.pdf');
          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Report is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },
    onRowSelected(item) {
      item.toggleDetails();
    },

    onRowPurchaseSelected(item) {
      if (this.$Can('order_show')) {
        router.push({ name: 'expense-preview', params: { id: item[0].orderId } });
      }
    },

    onRowSaleSelected(item) {
      if (this.$Can('order_show')) {
        router.push({ name: 'income-preview', params: { id: item[0].orderId } });
      }
    },
    onError(event) {
      event.target.src = '../no-car.png';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY');
      }
    },

    getItems() {
      this.loading = true;
      store
        .dispatch('accounting/filterPeriod', { start: this.filterDateStart, end: this.filterDateEnd, type: 'sale', filterUser: this.treeStatus })
        .then((res) => {
          let filterObj = {
            perPage: this.perPage,
            status: this.treeStatus ? this.treeStatus : [],
          };

          localStorage.setItem('filterSaleReport', JSON.stringify(filterObj));

          this.totalRows = res.data.data.length;
          this.totalPurchasePrice = res.data.totalPurchase;
          this.totalSellingPrice = res.data.sellingTotal;
          this.totalProfit = res.data.totalProfit;
          this.totalCostOfSales = res.data.totalCostOfSales;
          this.filterDateStart = res.data.startDate;
          this.filterDateEnd = res.data.endDate;

          res.data.data.forEach((element) => {
            element._showDetails = false;
          });

          this.fetchInventorys = res.data.data;
          this.listInventorys = res.data.data;

          if (res.data.headers) {
            res.data.headers.forEach((items) => {
              this.colWidthFactor = this.colWidthFactor + res.data.columns.find((x) => x.value == items).percentage;
            });

            res.data.headers.forEach((items) => {
              var columns = res.data.columns.find((x) => x.value == items);
              var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
              this.inHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
            });
            this.calculateClass();

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: 'Users',
          children: [],
        },
      ];

      this.userOptions.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.label,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      this.statusOptions = treeOptionsStatus;
      this.storageSaleItem();
    },

    storageSaleItem() {
      let key = JSON.parse(localStorage.getItem('filterSaleReport'));

      if (key == null) {
        this.getItems();
      }

      if (key) {
        // this.perPage = key.perPage ? key.perPage : 10;
        // this.page = key.page ? key.page : 1;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;
        this.getItems();
      }
    },

    getUsers() {
      this.loading = true;
      store.dispatch('accounting/fetchUsers', {}).then((res) => {
        this.userOptions = res.data;
        this.filterTreeSelect();
      });
    },
  },

  created() {
    this.getUsers();

    // this.getItems();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
